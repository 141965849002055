import { render, staticRenderFns } from "./YHeader.vue?vue&type=template&id=786c6f68&scoped=true"
import script from "./YHeader.vue?vue&type=script&lang=js"
export * from "./YHeader.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/header.scss?vue&type=style&index=0&id=786c6f68&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "786c6f68",
  null
  
)

export default component.exports