<template>
  <div>
    <YHeader></YHeader>
    <div class="news">
      <div class="top-bg">
        <div class="content">
          <div class="b-left">
            <p class="title">新闻动态</p>
            <p>企业、行业、政策实时动态</p>
          </div>
<!--          <div class="b-right">-->
<!--            云背篓实时的发展动态，包括重大活动、产品宣传、业务进展等，三农领域行业资讯，与国家政策的最新走向。-->
<!--          </div>-->
        </div>
      </div>
      <div class="content-list">
        <div class="writings">
          <el-carousel :interval="5000" arrow="never" trigger="click" indicator-position="outside" height="408px">
            <el-carousel-item v-for="(v, k) in news.slice(0,3)" :key="k">
              <div class="writings-box">
                <div class="writings-left">
                  <img :src="v.cover">
                </div>
                <div class="writings-right">
                  <p class="writings-title">{{ v['year'] }}年{{ v['month'] }}月{{ v['day'] }}日</p>
                  <p class="writings-center">第七届进博会首日 | 云背篓集团、国机海南与哈萨克斯坦贸易政策发展中心QazTrade 股份公司签署合作备忘录</p>
                  <a @click="toPage(v.id)" class="writings-but">查看详情</a>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="article-list">
        <div class="item" v-for="(v, k) in news" :key="k" @click="toPage(v.id)">
          <div class="item-left">
            <p class="l-title">{{ v.month }}</p>
            <p class="l-center"><span>{{ v.year }}</span>&nbsp;<span>{{ v.day }}</span></p>
          </div>
          <div class="item-center">{{ v.name }}</div>
          <div class="item-right">
            <a><span>查看详情</span>&nbsp;<i></i></a>
          </div>
        </div>
      </div>
      <div class="bt-pagination">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="currentChange"
        >
        </el-pagination>
      </div>
      <YFooter></YFooter>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/view/news.scss" scoped></style>
<script>
import YHeader from '@/components/YHeader.vue'
import YFooter from '@/components/YFooter.vue'
import {articleList} from "@/api";
export default {
  name: 'news',
  components:{
    YHeader: YHeader,
    YFooter: YFooter
  },
  data() {
    return{
      page: 1,
      limit: 10,
      total: 0,
      news: []
    }
  },
  methods: {
    init(){
      articleList({page:this.page, limit: this.limit}).then(res =>{
        if (res.code === 1001){
          this.total = res.option.count;
          this.news = res.option.data;
        }
      })
    },

    toPage(id) {
      this.$common.toDetail(id);
    },

    currentChange(page) {
      this.page = page;
      this.init();
    }
  },

  mounted() {
    this.init();
  },
}
</script>

