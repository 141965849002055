import request from '@/utils/request'

// 导航栏
export function getNav(data) {
    return request({
        url: '/nav',
        method: 'post',
        params: data,
    })
}

// 文章列表
export function articleList(data) {
    return request({
        url: '/article',
        method: 'post',
        params: data,
    })
}

// 单个文章内容
export function articleOne(data) {
    return request({
        url: '/article_one',
        method: 'post',
        params: data,
    })
}