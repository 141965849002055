<template>
  <div>
    <YHeader></YHeader>
    <div class="profile">
      <div class="b-top-bg">
        <div class="b-top-box">
          <p class="b-top-title">集团简介</p>
        </div>
      </div>
<!--      公司成立-->
      <div class="content-gs">
        <div class="gs-box">
          <div class="gs-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/12.png">
          </div>
          <div class="gs-right">
            <p>公司成立</p>
            <div class="gs-basis">
              云背篓集团，成立于2017年6月，基于中华全国供销合作总社综合改革，依托四川省级供销合作平台，聚焦乡村产业振兴，旨在构建农业产业化优质服务的集团公司。
            </div>
          </div>
        </div>
      </div>
<!--      集团布局-->
      <div class="content-jt">
        <div class="jt-box">
          <div class="jt-title">
            <p >集团布局</p>
          </div>
          <p class="jt-basis">
            集团在农业产业领域实践的道路上，已形成公益品牌促进“两端”、“两网”建设、装备设施服务产业振兴的解决方案和服务体系，
            先后参与了“川字号”、“四川扶贫”、“扶贫（四川）”、“天府乡村&川字号农产品全球运营中心”的筹备及运营工作，开发了“菌类工厂”
            -食用菌智能化菇房、三次发酵工艺、加工包装产业线⼀体的技术解决⽅案。立足四川，着力西部，放眼全球，打造出“1067”数字供应链系统、“12131”战略。
          </p>
          <p class="jt-basis">
            旗下孵化了云背篓、戎品集、长寿嘉陵、舌尖九寨、藏壤风情肉、格桑塔、壤蜜等食品品牌个，戎品集-项目品牌、背篓万里行-公益助农、数字背篓-科技装备、背篓云仓-县域物流、背背熊-文创品牌、YBL人才中心-智库等品牌矩阵。
          </p>
        </div>
      </div>
<!--      集团发展-->
      <div class="content-fz">
        <div class="fz-box">
          <div class="fz-left">
            <p>集团发展</p>
            <div class="fz-basis">
              集团历经近八年的发展，业务聚焦农特产品供销、科技装备信息化、大宗粮油三大领域，从供销体系最具投资价值的省级创新型平台集团发展为交易服务规模靠前、
              品牌效益突出、市场化运行机制明显，公益助农并行，立足四川、放眼全球的大型农业科技集团，通过屡屡创新的举措，在农业领域成功发起了⼀系列可落地、
              可复制的项目案例，形成了⼀定经验，并得到社会各界广泛认同和称赞。
            </div>
          </div>
          <div class="fz-right">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/14.png">
          </div>
        </div>
      </div>

      <!--      集团愿景-->
      <div class="content-yj">
        <div class="yj-box">
          <div class="yj-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/15.png">
          </div>
          <div class="yj-right">
            <p>集团愿景</p>
            <div class="yj-basis">
              助推农业产业现代化发展、
              <br>
              让农村更美好、让农⺠更富裕。
            </div>
            <p>集团使命</p>
            <div class="yj-basis">
              用科技装备助力农业产业化结构转型升级，
              <br>
              用数字供应链将中国食品呈现世界，
              <br>
              不遗余力构建中国食品全球流通大动脉。
            </div>
          </div>
        </div>
      </div>
      <YFooter></YFooter>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/view/profile.scss" scoped />
<script>
import YHeader from "@/components/YHeader.vue";
import YFooter from "@/components/YFooter.vue";
export default {
  name: 'demo',
  components:{
    YHeader: YHeader,
    YFooter: YFooter
  },
  data() {
    return {

    }
  }
}
</script>