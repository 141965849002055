import axios from 'axios'  //引入axios

let baseURL = "https://admin.yunbeilou.com/api.php/"
if (process.env.NODE_ENV === 'development') {
    console.log('开发环境')
    baseURL = "http://www.api.yblpcadmin.com/api.php/"
} else {
    console.log('生产环境')
}
//创建一个新的axios实例
const service = axios.create({
    baseURL: baseURL,
    timeout: 20000    //设置超时时间，超过该时间就不会发起请求
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    function (response) {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        return response.data
    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

// 对外暴露
export default service