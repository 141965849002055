var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('YHeader'),_c('div',{staticClass:"history"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('YFooter')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-bg"},[_c('div',{staticClass:"top-box"},[_c('p',{staticClass:"top-title"},[_vm._v("发展历程")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-cl"},[_c('div',{staticClass:"cl-box"},[_c('div',{staticClass:"cl-left"},[_c('img',{attrs:{"src":"https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/17.png"}})]),_c('div',{staticClass:"cl-right"},[_c('p',{staticClass:"cl-title1"},[_vm._v("2017")]),_c('p',{staticClass:"cl-title2"},[_vm._v("公司成立")]),_c('div',{staticClass:"cl-basis"},[_vm._v(" 四川供销微众电子商务有限公司在云龙大厦成立，川字号丨四川农特产品品牌公共服务平台上线，背篓万里公益主题活动发布，四川大小凉山片区公司-攀枝花农产品经营有限公司成立。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-tp"},[_c('div',{staticClass:"tp-box"},[_c('div',{staticClass:"tp-left"},[_c('img',{attrs:{"src":"https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/18.png"}})]),_c('div',{staticClass:"tp-right"},[_c('p',{staticClass:"tp-title1"},[_vm._v("2019")]),_c('p',{staticClass:"tp-title2"},[_vm._v("攻坚脱贫")]),_c('div',{staticClass:"tp-basis"},[_vm._v(" 扶贫小超.版正式上线，“贫困地区农产品交易平台”率先 在西南地区启动运营，扶贫832 fupin832.com在北京正式上线， 四川扶贫官方抖音上线。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-jj"},[_c('div',{staticClass:"jj-box"},[_c('div',{staticClass:"jj-left"},[_c('img',{attrs:{"src":"https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/19.png"}})]),_c('div',{staticClass:"jj-right"},[_c('p',{staticClass:"jj-title1"},[_vm._v("2022")]),_c('p',{staticClass:"jj-title2"},[_vm._v("聚焦主业")]),_c('div',{staticClass:"jj-basis"},[_vm._v(" 天府乡村&川字号农产品全球运营中心正式启航，“1067”数 字供应链系统筹备及1.0版本上线，菌类工厂研发成功并上线。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-dl"},[_c('div',{staticClass:"dl-box"},[_c('div',{staticClass:"dl-left"},[_c('img',{attrs:{"src":"https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/20.png"}}),_c('img',{staticStyle:{"margin-top":"10px"},attrs:{"src":"https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/21.png"}})]),_c('div',{staticClass:"dl-right"},[_c('p',{staticClass:"dl-title1"},[_vm._v("2023")]),_c('p',{staticClass:"dl-title2"},[_vm._v("砥砺前行")]),_c('div',{staticClass:"dl-basis"},[_vm._v(" 构建川货出川出海大动脉，提出“12131”战略，“1067”数字 供应链系统2.0上线。海南自贸港云背篓科技公司落地。 ")]),_c('div',{staticClass:"dl-basis"},[_vm._v(" 在成都市退役军人事务局指导下，共同参与打造“戎品集”退 役军人就业创业公共品牌。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-kj"},[_c('div',{staticClass:"kj-box"},[_c('div',{staticClass:"kj-left"},[_c('img',{attrs:{"src":"https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/22.png"}})]),_c('div',{staticClass:"kj-right"},[_c('p',{staticClass:"kj-title1"},[_vm._v("2024")]),_c('p',{staticClass:"kj-title2"},[_vm._v("科技赋能")]),_c('div',{staticClass:"kj-basis"},[_vm._v(" 加强科技创新，为传统产业注入新质生产力，引进资本，成立 箱冯科技，孵化“今菇棒”品牌，估值实现1亿美元。 ")])])])])
}]

export { render, staticRenderFns }