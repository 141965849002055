<template>
  <div class="header" v-show="showNav">
    <div class="nav">
      <div class="logo">
        <a href="/">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v20/i/20230630/16881110145051.png">
        </a>
      </div>
      <ul class="ul-one">
        <li class="li-one" v-for="(v, k) in nav" :key="k" :class="v.child.length > 0 ? 'drop-down' : ''">
          <a v-if="v.child.length === 0" :href="v.url" :target="v.newpage" class="a-one">{{ v.name }}</a>
          <div v-if="v.child.length !== 0">
            <span class="span-over">{{ v.name }}</span>
            <ul class="ul-tow" >
              <li v-for="(v1, k1) in v.child" :key="k1"><a :href="v1.url" :target="v1.newpage">{{ v1.name }}</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/components/header.scss" scoped></style>
<script>
import {getNav} from "@/api"
export default {
  name: 'YHeader',
  data() {
    return{
      showNav: true,
      marginTop: 0,
      offsetX: 0,
      nav:[]
    }
  },
  methods: {
    init() {
      getNav().then(res =>{
        if (res.code === 1001){
          this.nav = res.option
        }
      })
    },
    handleScroll() {
      let header = document.querySelector('.header');
      // 向上滚动时展示导航
      if (this.marginTop > document.documentElement.scrollTop){
        this.showNav = true;
        header.style.position = 'fixed';
      }else if (this.marginTop < document.documentElement.scrollTop){
        this.showNav = false;
        header.style.position = 'absolute';
      }
      // 到顶时修改定位方式，让导航栏跟随文档流
      if (document.documentElement.scrollTop === 0){
        header.style.position = 'absolute'
      }
      // 不在顶部时，水平滚动时导航栏隐藏
      if (this.offsetX !== window.pageXOffset && document.documentElement.scrollTop !== 0){
        this.showNav = false;
        header.style.position = 'absolute'
      }
      this.offsetX = window.pageXOffset;
      this.marginTop = document.documentElement.scrollTop
    }
  },

  mounted() {
    this.init();
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

