<template>
  <div class="main">
    <YHeader></YHeader>
    <div class="index-banner">
      <el-carousel :interval="5000" arrow="never" trigger="click" height="400px">
        <el-carousel-item v-for="(v, k) in banners" :key="k">
          <div class="banner" :style="{ backgroundImage: 'url(' + v + ')' }"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="body-box">
<!--      集团简介-->
      <div class="container">
        <div class="swiper-wrapper">
          <div class="swiper-card">
            <p class="title">云背篓集团</p>
            <p class="subtitle">农业产业优质服务商</p>
            <p class="basis">云背篓集团，成立于2017年6月，基于中华全国供销合作总社综合改革，依托四川省级供销合作平台，聚焦乡村产业振兴，旨在构建农业产业化优质服务的集团公司。</p>
            <a href="/profile.html" class="a-button">了解详情</a>
          </div>
        </div>
      </div>
<!--      背篓动态-->
      <div class="trends">
        <div class="block trends-content" :style="{ marginTop: margin + 'px', transition: 'margin 0.5s ease-out' }">
          <div class="trends-title">
            <div class="title-left">
              <span>背篓动态</span>
            </div>
            <a href="/news.html" class="el-button">查看更多</a>
          </div>
          <div class="trends-row">
            <a href="javascript:void(0)" class="trends-one" @click="toPage(news[0]['id'])">
              <img :src="news[0]['cover']">
              <div class="item-con">
                <p>{{ news[0]['name'] }}</p>
                <div class="item-but">{{ news[0]['year'] }}年{{ news[0]['month'] }}月{{ news[0]['day'] }}日</div>
              </div>
            </a>
            <a href="javascript:void(0)" class="trends-item" @click="toPage(news[1]['id'])">
              <div class="img-div">
                <img :src="news[1]['cover']" class="el-image">
              </div>
              <div class="item-con">
                <p>{{ news[1]['name'] }}</p>
                <div class="item-but">{{ news[0]['year'] }}年{{ news[0]['month'] }}月{{ news[0]['day'] }}日</div>
              </div>
            </a>
          </div>
          <div class="trends-row">
            <a href="javascript:void(0)"
               class="trends-item"
               v-for="(v, k) in news.slice(2,5)"
               :key="k"
               @click="toPage(v['id'])"
            >
              <div class="img-div">
                <img :src="v.cover" class="el-image">
              </div>
              <div class="item-con">
                <p>{{ v.name }}</p>
                <div class="item-but">{{ v['year'] }}年{{ v['month'] }}月{{ v['day'] }}日</div>
              </div>
            </a>
          </div>
        </div>
      </div>
<!--      核心主业-->
      <div class="business">
        <div class="b-title">
          <span>核心主业</span>
        </div>
        <div class="block business-content">
          <a href="/business.html" class="business-item">
            <div class="image-bg" :style="{ backgroundImage: 'url(https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/04.png)' }"></div>
            <div class="business-con">
              <p class="business-title">农特产品供销领域</p>
            </div>
            <p class="b-content">以“天府乡村&川字号农产品全球运营中心”为载体，聚焦农特产品流通主业，助力川货出川出海。</p>
          </a>
          <a href="/business.html" class="business-item">
            <div class="image-bg" :style="{ backgroundImage: 'url(https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/03.png)' }"></div>
            <div class="business-con">
              <p class="business-title">大宗粮油领域</p>
            </div>
            <p class="b-content">融合粮油原产地基地资源，聚力从农场到工厂的供应链服务，缩短国内国际粮油大宗交易链条，与客户共享产业链利益。</p>
          </a>
          <a href="/business.html" class="business-item">
            <div class="image-bg" :style="{ backgroundImage: 'url(https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/02.png)' }"></div>
            <div class="business-con">
              <p class="business-title">科技装备信息化领域</p>
            </div>
            <p class="b-content">充分发挥供销体系的下行能力，以行业领先的智能装备及信息技术下沉地方，以科技抓生产力，发挥联农带农的示范作用，促进地方农业现代化转型。</p>
          </a>
        </div>
      </div>
      <!--    川字号-->
      <div class="czh">
        <div class="c-content">
          <div class="c-title">
            <div class="c-title-left">
              <p>天府乡村&川字号</p>
              <p>农产品全球运营中心</p>
            </div>
            <div class="c-title-right">
              <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/31.png">
            </div>
          </div>
          <div class="c-image">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/05.png">
          </div>
          <div class="c-basis">
            <p>
              天府乡村&川字号农产品全球运营中心（以下简称“中心”）是四川省农产品经营集团为深⼊贯彻四川省第十二次党代会会议精神，
              以省委省政府《关于做好2022年“三农”重点工作全⾯推进乡村振兴的意见》为指引，率领旗下四川供销电子商务有限责任公司、
              四川川供天下农业发展有限公司、四川云背篓科技集团有限公司联合成立，四川云背篓⻝品集团有限公司为执行单位。
            </p>
          </div>
          <div class="c-jump-but">
            <a href="http://chuanzihao.com/" class="a-button" target="_blank">进入运营中心官网</a>
          </div>
        </div>
      </div>
      <YFooter></YFooter>
    </div>

  </div>
</template>
<style lang="scss" src="@/assets/css/view/index.scss" scoped></style>
<script>
import YHeader from '@/components/YHeader.vue'
import YFooter from '@/components/YFooter.vue'
import {articleList} from "@/api";
export default {
  name: 'index',
  components:{
    YHeader: YHeader,
    YFooter: YFooter
  },
  data() {
    return{
      banners: [
          'https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/01.png',
      ], // 主页轮播背景图
      margin: 0, // 屏幕下滑的时候会替换成-170让“背篓动态”往上滑
      news: [], // 背篓动态（最多展示五个）
    }
  },
  methods: {
    init(){
      articleList().then(res =>{
        if (res.code === 1001){
          this.news = res.option.data
        }
      })
    },
    handleScroll() {
      // 轮播指示器dom
      let indicators = document.querySelector('.el-carousel__indicators');
      if (window.scrollY === 0){
        this.margin = 0;
        indicators.style.bottom = '20px';
      }else{
        this.margin = -170;
        indicators.style.bottom = '150px';
      }
    },
    toPage(id) {
      this.$common.toDetail(id)
    }
  },

  mounted() {
    this.init();
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

