<template>
  <div class="header">
    <div class="nav">
      <div class="logo">
        <a href="/">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v20/i/20230630/16881131805440.png">
        </a>
      </div>
      <ul class="ul-one">
        <li class="li-one" v-for="(v, k) in nav" :key="k" :class="v.child.length > 0 ? 'drop-down' : ''">
          <a v-if="v.child.length === 0" :href="v.url" :target="v.newpage" class="a-one">{{ v.name }}</a>
          <div v-if="v.child.length !== 0">
            <span class="span-over">{{ v.name }}</span>
            <ul class="ul-tow" >
              <li v-for="(v1, k1) in v.child" :key="k1"><a :href="v1.url" :target="v1.newpage">{{ v1.name }}</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/components/headerDetail.scss" scoped></style>
<script>
import {getNav} from "@/api";

export default {
  name: 'YHeaderDetail',
  data() {
    return{
      nav:[]
    }
  },
  methods: {
    init() {
      getNav().then(res =>{
        if (res.code === 1001){
          this.nav = res.option
        }
      })
    },
  },

  mounted() {
    this.init();
  },
}
</script>

