<template>
  <div>
    <YHeader></YHeader>
    <div class="history">
      <div class="top-bg">
        <div class="top-box">
          <p class="top-title">发展历程</p>
        </div>
      </div>
<!--      公司成立-->
      <div class="contact-cl">
        <div class="cl-box">
          <div class="cl-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/17.png">
          </div>
          <div class="cl-right">
            <p class="cl-title1">2017</p>
            <p class="cl-title2">公司成立</p>
            <div class="cl-basis">
              四川供销微众电子商务有限公司在云龙大厦成立，川字号丨四川农特产品品牌公共服务平台上线，背篓万里公益主题活动发布，四川大小凉山片区公司-攀枝花农产品经营有限公司成立。
            </div>
          </div>
        </div>
      </div>
<!--      脱贫攻坚-->
      <div class="contact-tp">
        <div class="tp-box">
          <div class="tp-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/18.png">
          </div>
          <div class="tp-right">
            <p class="tp-title1">2019</p>
            <p class="tp-title2">攻坚脱贫</p>
            <div class="tp-basis">
              扶贫小超.版正式上线，“贫困地区农产品交易平台”率先
              在西南地区启动运营，扶贫832 fupin832.com在北京正式上线，
              四川扶贫官方抖音上线。
            </div>
          </div>
        </div>
      </div>
<!--      聚焦主业-->
      <div class="contact-jj">
        <div class="jj-box">
          <div class="jj-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/19.png">
          </div>
          <div class="jj-right">
            <p class="jj-title1">2022</p>
            <p class="jj-title2">聚焦主业</p>
            <div class="jj-basis">
              天府乡村&川字号农产品全球运营中心正式启航，“1067”数
              字供应链系统筹备及1.0版本上线，菌类工厂研发成功并上线。
            </div>
          </div>
        </div>
      </div>
<!--      砥砺前行-->
      <div class="contact-dl">
        <div class="dl-box">
          <div class="dl-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/20.png">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/21.png" style="margin-top: 10px">
          </div>
          <div class="dl-right">
            <p class="dl-title1">2023</p>
            <p class="dl-title2">砥砺前行</p>
            <div class="dl-basis">
              构建川货出川出海大动脉，提出“12131”战略，“1067”数字
              供应链系统2.0上线。海南自贸港云背篓科技公司落地。
            </div>
            <div class="dl-basis">
              在成都市退役军人事务局指导下，共同参与打造“戎品集”退
              役军人就业创业公共品牌。
            </div>
          </div>
        </div>
      </div>
<!--      科技赋能-->
      <div class="contact-kj">
        <div class="kj-box">
          <div class="kj-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/22.png">
          </div>
          <div class="kj-right">
            <p class="kj-title1">2024</p>
            <p class="kj-title2">科技赋能</p>
            <div class="kj-basis">
              加强科技创新，为传统产业注入新质生产力，引进资本，成立
              箱冯科技，孵化“今菇棒”品牌，估值实现1亿美元。
            </div>
          </div>
        </div>
      </div>
      <YFooter></YFooter>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/view/history.scss" scoped />
<script>
import YHeader from "@/components/YHeader.vue";
import YFooter from "@/components/YFooter.vue";
export default {
  name: 'history',
  components:{
    YHeader: YHeader,
    YFooter: YFooter
  },
  data() {
    return {

    }
  }
}
</script>