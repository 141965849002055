import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/view/index.vue";
import news from "@/view/news.vue";
import detail from "@/view/detail.vue";
import business from "@/view/business.vue";
import profile from "@/view/profile.vue";
import history from "@/view/history.vue";
import contact from "@/view/contact.vue";

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', name: 'index', component: index}, // 首页
        {path: '/news.html', name: 'news', component: news}, // 新闻动态
        {path: '/detail:id.html', name: 'detail', component: detail, props:true}, // 文章详情
        {path: '/business.html', name: 'business', component: business}, // 核心主业
        {path: '/profile.html', name: 'profile', component: profile}, // 集团简介
        {path: '/history.html', name: 'history', component: history}, // 发展历程
        {path: '/contact.html', name: 'contact', component: contact}, // 联系我们
    ]
});

export default router;