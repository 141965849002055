<template>
  <div>
    <YHeader></YHeader>
    <div class="contact">
      <div class="top-bg">
        <div class="top-box">
          <p class="top-title">联系我们</p>
        </div>
      </div>
      <div class="contact-lx">
        <div class="lx-box">
          <div class="lx-box-content">
            <div class="lx-row">
              <i></i>
              <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/25.png" alt="">
              <span class="lx-label" style="width: 140px;">云背篓总部：</span>
              <span class="lx-content">成都市青羊区青羊工业总部基地G区7栋9楼</span>
            </div>
            <div class="lx-row">
              <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/26.png" alt="">
              <span class="lx-label">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;编：</span>
              <span class="lx-content">610073</span>
            </div>
            <div class="lx-row">
              <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/27.png" alt="">
              <span class="lx-label">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</span>
              <span class="lx-content">yunbeilou@126.com</span>
            </div>
            <div class="lx-row">
              <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/28.png" alt="">
              <span class="lx-label">客服电话：</span>
              <span class="lx-content">400-0800-623</span>
            </div>
            <img class="lx-bt-img" src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/24.png" alt="">
          </div>
        </div>
      </div>
      <YFooter></YFooter>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/view/contact.scss" scoped />
<script>
import YHeader from "@/components/YHeader.vue";
import YFooter from "@/components/YFooter.vue";
export default {
  name: 'contact',
  components:{
    YHeader: YHeader,
    YFooter: YFooter
  },
  data() {
    return {

    }
  }
}
</script>