<template>
  <div class="main">
    <YHeader></YHeader>
    <div class="business">
      <div class="b-top-bg">
        <div class="b-top-box">
          <p class="b-top-title">核心主业</p>
        </div>
      </div>
<!--      农特产品-->
      <div class="content-nt">
        <div class="nt-box">
          <div class="nt-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/09.png">
          </div>
          <div class="nt-right">
            <p>农特产品供销领域</p>
            <div class="nt-basis">
              以“天府乡村&川字号农产品全球运营中心”为载体，聚焦农特产品流通主业，助力川货出川出海。
            </div>
          </div>
        </div>
      </div>
<!--      大宗粮油-->
      <div class="content-dz">
        <div class="dz-box">
          <div class="dz-left">
            <p>大宗粮油领域</p>
            <div class="dz-basis">
              融合粮油原产地基地资源，聚力从农场到工厂的供应链服务，缩短国内国际粮油大宗交易链条，与客户共享产业链利益。
            </div>
          </div>
          <div class="dz-right">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/08.png">
          </div>
        </div>
      </div>
<!--      科技装备-->
      <div class="content-zb">
        <div class="zb-box">
          <div class="zb-left">
            <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/07.png">
          </div>
          <div class="zb-right">
            <p>科技装备信息化领域</p>
            <div class="zb-basis">
              充分发挥供销体系的下行能力，以行业领先的智能装备及信息技术下沉地方，以科技抓生产力，发挥联农带农的示范作用，促进地方农业现代化转型。
            </div>
          </div>
        </div>
      </div>
      <div class="content-jg">
        <div class="jg-img">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/10.png">
        </div>
      </div>
      <YFooter></YFooter>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/view/business.scss" scoped />
<script>
import YHeader from '@/components/YHeader.vue'
import YFooter from '@/components/YFooter.vue'
export default {
  name: 'business',
  components:{
    YHeader: YHeader,
    YFooter: YFooter
  },
  data() {
    return {

    }
  }
}
</script>