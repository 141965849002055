<template>
  <div class="footer">
    <div class="footer-content">
      <div class="center-left">
        <div class="center-logo">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v30/images/29.png" alt="">
        </div>
        <div class="center-basis">
          <div class="t-1">
            <span class="span">云背篓总部：</span>
            <span>成都市青羊区青羊工业总部基地G区7栋9楼</span>
          </div>
          <div class="t-1">
            <span class="span">邮编：</span>
            <span>610073</span>
          </div>
          <div class="t-1">
            <span class="span">邮箱：</span>
            <span>yunbeilou@126.com</span>
          </div>
          <div class="t-1">
            <span class="span">客服电话：</span>
            <span>400-0800-623</span>
          </div>
        </div>
      </div>
      <div class="center-center">
        <div class="c-code">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v20/i/20230630/16881123163979.jpeg" width="100%">
          <p>云背篓公众号</p>
        </div>
        <div class="c-code">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v20/i/20230630/16881123197265.jpeg" width="100%">
          <p>运营中心公众号</p>
        </div>
        <div class="c-code">
          <img src="https://bucket-yunbeilou.oss-cn-chengdu.aliyuncs.com/yunbeilou/v20/i/20230630/16881123227811.jpeg" width="100%">
          <p>云背篓订阅号</p>
        </div>
      </div>
    </div>
    <div class="center-bottom">
      <p class="rsecord"><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备17026317号-1</a></p>
      <p class="copyright">Copyright © 2017-2025 四川云背篓科技集团有限公司</p>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/components/footer.scss" scoped></style>
<script>
export default {
  name: 'YFooter',
  data() {
    return{

    }
  },
  methods: {

  },
}
</script>
