<template>
  <div>
    <YHeaderDetail></YHeaderDetail>
    <div class="detail">
      <div class="d-content">
        <div class="title">
          {{ news_data['name'] }}
        </div>
        <div class="basis">
          <p>来源：{{ news_data['source'] }} </p>
          <p>&nbsp;|&nbsp;{{ news_data['year'] }}年{{ news_data['month'] }}月{{ news_data['day']}}日 {{ news_data['time'] }}</p>
        </div>
        <div class="rich" v-html="news_data['content']">

        </div>
        <div class="bt-box">
          <p class="prev">
            <span class="label">上一篇：</span>
            <span v-if="news_data['last_text_id'] !== ''" class="p-title" @click="toPage(news_data['last_text_id'])">{{ news_data['last_text_name'] }}</span>
            <span v-if="news_data['last_text_id'] === ''">没有了！</span>
          </p>
          <p class="prev">
            <span class="label">下一篇：</span>
            <span v-if="news_data['next_text_id'] !== ''" class="p-title" @click="toPage(news_data['next_text_id'])">{{ news_data['next_text_name'] }}</span>
            <span v-if="news_data['next_text_id'] === ''">没有了！</span>
          </p>
        </div>
      </div>
      <YFooter></YFooter>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/view/detail.scss" scoped></style>
<script>
import YHeaderDetail from '@/components/YHeaderDetail.vue'
import YFooter from '@/components/YFooter.vue'
import {articleOne} from "@/api";
export default {
  name: 'detail',
  components:{
    YHeaderDetail: YHeaderDetail,
    YFooter: YFooter
  },
  data() {
    return{
      news_data: {},
    }
  },
  methods: {
    init(id) {
      articleOne({id:id}).then(res =>{
        if (res.code === 1001){
          this.news_data = res.option;
        }
      })
    },
    toPage(id) {
      this.$common.toDetail(id);
      window.scrollTo(0, 0);
      location.reload();
    },
  },
  mounted() {
    this.init(this.$route.params.id)
  }
}
</script>

